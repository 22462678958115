export const WebRoutes = {
  DASHBOARD: "/",
  COMPETATIVE_EXAM: "/competative-exam",
  COLLEGE: "/college",
  SCHOOL: "/school",
  CENTERS: "/our-centers",
  ABOUT_US: "/about-Us",
  CONTACT_US: "/contact-us",
  BOARD_DIRECTORS: "/board-directors",
  KEY_MANAGEMENT: "/key-management",
  BOARD_COMMITTEE: "/board-committee",
  AWARD_RECOGNITION: "/awards-recognition",
  AWARD_DETAIL: "/awards-detail",
  CORPORATE_GOVERNANCE: "/corporate-governance",
  INVESTOR_PRESENTATIONS: "/investor-presentations",
  PRESS_RELEASE: "/press-release",
  POSTAL_BALLOT: "/postal-ballot",
  STATUTORY_COMMUNICATION: "/statutory-communication",
  SHAREHOLDING_PATTERN: "/shareholding-pattern",
  CIRP: '/cirp',
  REPORTS: "/reports",
  BOARD_MEETING : "/board-meeting",
  MEMBER_MEETING : "/member-meeting",
  ANNUAL_RETURN: "/annual-returns",
  RESEARCH_REPORT: "/research-report",
  SUBSIDIARY_MTEDUCARE: "/subsidiaries-mt",
  ANNUAL_REPORT: "/annual-report",
  PHOTO_GALLARY: "/gallary",
  VIDEO_GALLARY: "/video-gallary",
  GALLARY_GRID: "/gallaryList/",
  CSR: "/csr",
  MEDIA: "/media",
  CAREER: "/careers",
  CAREER_DETAILS: "/careers-details/",
  PRIVACY_POLICY: "/privacy-policy",
  DISCLAIMER: "/disclaimer",
  TERMS_SERVICE: "/terms-and-conditions",
  ODR: "/odr-portal",
};
